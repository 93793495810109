<template>
<div>
	<TopNav></TopNav>
	<Header></Header>
	<div class="Area1">
		<div class="Wrap">
			
			<div class="Left">
				
				<el-carousel height="450px" trigger="click">
				  <el-carousel-item v-for="(ad,adI) in SwiperList" :key="adI">
					   <div>
						   <img :src="ad.ShowThumb" style="height:350px;width: 100%;" />
					   </div>
				  </el-carousel-item>
				</el-carousel>
				
				
			</div>
			
			<div class="Right">
				
				<div class="Login">
					<h4>
						账号注册
					</h4>
					<li>
						<el-input v-model="Account" placeholder="请输入手机号"></el-input>
					</li>
					<li style="display: flex;justify-content: space-between;">
						<span style="flex: 1;margin-right:10px"><el-input v-model="MyTypeCode" placeholder="验证码"></el-input></span>
						<em>
							<el-button :disabled="btnDisabled" style="background-color: rgba(23,134,65,1);border-color: rgba(23,134,65,1);color: #fff;"  @click="NewMobileCode()">{{btnText}}</el-button>
						</em>
					</li>
					<li>
						<el-input v-model="Password" show-password placeholder="请输入密码"></el-input>
					</li>
					<li>
						<el-input v-model="Password2" show-password placeholder="请再次输入密码"></el-input>
					</li>
					<li style="display: flex;justify-content: space-between;align-items: center;font-size: 12px;">
						<span @click="$Jump('/about/argument')" style="cursor: pointer;"><i class="el-icon-check" style="border: 1px solid rgba(0,0,0,0.3);margin-right: 5px;"></i>我已阅读并同意用户协议</span>
						<label class="Forgot" @click="$Jump('/login')">已有账号?</label>
					</li>
					<li>
						<el-button style="width: 100%;background-color: rgba(23,134,65,1);color: #FFFFFF;border-color: rgba(23,134,65,1);" @click="Register()">注册</el-button>
					</li>
				</div>
				
			</div>
			
		</div>
	</div>
	<Footer></Footer>
</div>
</template>

<script>
	import {  Carousel,CarouselItem,Radio,Dialog } from 'element-ui';
	import md5 from 'md5';
	export default {
	  name: 'Register',
	  props: {
	  },
	  data() {
	      return {
	        Account: '',
			Password:'',
			Password2:'',
			SmsCodeId:'',//验证码验证代号
			MyTypeCode:'',//我输入的验证码
			btnDisabled:false,
			btnText:'获取验证码',
			SwiperList:[
				{
					Id:'1231',
					ShowTitle:'',
					JumpTarget:'',
					ShowThumb:'https://img.wojiasx.com/s/99a18ea7-2a30-48a7-9ca4-c2b4443f7f9c.jpeg',
					BgColor:'#286dff',
					JumpType:'uri',
				}
			]
	      }
	    },
	  components: {
		  "el-carousel":Carousel,
		  "el-carousel-item":CarouselItem,
		  "el-radio":Radio
	  },
	  created() {
	  	this.SwiperList()
	  },
	  methods:{
		  SuccessJump(){
			  this.$Jump('/login')
		  },
		  getSecond(wait){
			  let _this=this;
			  let _wait = wait;
			  if(wait == 0) {
				  this.btnDisabled=false;
				  this.btnText="获取验证码"
				  wait = _wait;
			  } else {
				  this.btnDisabled=true;
				  this.btnText="验证码(" + wait + "s)"
				  wait--;
				  setTimeout(function() {
						  _this.getSecond(wait);
					  },
					  1000);
			  }
		  },
		  NewMobileCode(){
			  
			  if(this.Account.length != 11){
				  this.$message('请输入正确的手机号')
				  return
			  }
			  let data = {
				Service:'Code',
				Class: 'SmsCode',
				Action: 'NewCode',
				Mobile:this.Account,//普通用户只能调取自己的信息
				SmsAction:'register',
			  }
			  this.$post(this.$store.getters.getApiHost,data)
			  .then((res) => {
				
				this.$message(res.Msg)
				if(res.ErrorId != 0){
					return
				}
				
				this.SmsCodeId = res.Data.SmsCodeId
				this.getSecond(60)
						
			  })
			  .catch(function (response) {
				this.$message('网络请求错误')
			  })
		  },
		  GetSwiper(){
		  			  let data = {
		  			  	Service:'Ad',
		  			  	Class: 'Ad',
		  			  	Action: 'List',
		  			  	Page:1,
		  			  	PageSize:3,
		  			  	SKey:'pc_login_ad'
		  			  }
		  			  this.$post(this.$store.getters.getApiHost,data)
		  			  .then((res) => {
		  			  	
		  			  	if(res.ErrorId != 0){this.$message(res.Msg);return;}
		  			  	if(res.Data.AdList == undefined || res.Data.AdList == null || res.Data.AdList.length == 0){
		  			  		return
		  			  	}
		  			  	this.SwiperList = res.Data.AdList
		  			  	
		  			  })
		  			  .catch(function (response) {
		  			  	this.$message('网络请求错误')
		  			  })
		  },
		  Register(){
			  
			if(this.Password != this.Password2){
				this.$message('两次密码不一致,请重设')
				return
			}
			  
			let that = this
			let data = {
				Service:'User',
				Class: 'User',
				Action: 'Register',
				Account:that.Account,
				Password:md5(that.Password),
				SmsCodeId:this.SmsCodeId,
				Code:this.MyTypeCode,
			}
			this.$post(that.$store.getters.getApiHost,data)
			.then((response) => {
				
				if(response.ErrorId != 0){
					that.$message({
					  message: response.Msg,
					  type: 'error'
					});
					
					return
				}
				
				localStorage.setItem('RememberAccount', that.Account)
				that.$message({
				  message: '注册成功，即将进入登录页',
				  type: 'success'
				});
								
				setTimeout(that.SuccessJump(),1500)

			})
			.catch(function (response) {
				this.$message('网络请求错误')
			})
		  }
	  }
	}
</script>

<style scoped>
	
.Area1{
	background-color: rgba(23,134,65,1);
	height: 350px;
	padding: 20px 0px;
}
.Area1 .Left{
	width: 850px;
	height: 350px;
}
.Area1 .Right{
	flex: 1;
	background-color: #FFFFFF;
}
.Login{
	
}
.Login h4{
	font-size: 16px;
	width: 80%;
	margin-left: auto;
	margin-right: auto;
}
.Login li{
	width: 80%;
	margin-left: auto;
	margin-right: auto;
	margin-top: 10px;
}
.Login .Forgot{
	float: right;
	font-size: 12PX;
	cursor: pointer;
}
</style>
